<template>
  <div>
    <v-card
      width="400px"
      class="mx-auto mt-5"
      :loading="isProcessing"
      :disabled="isProcessing"
    >
      <v-card-text>
        <div class="d-flex justify-center">
          <v-img max-width="60%" src="@/assets/images/yatoite-logo.svg"></v-img>
        </div>
        <p class="text-center">
          {{ login_label }}
        </p>
        <ValidationObserver ref="observer" v-slot="{ invalid }">
          <v-form @submit.prevent="onSubmit()">
            <ValidationProvider
              v-slot="{ errors, valid }"
              name="ユーザー名"
              rules="required|max:20"
            >
              <v-text-field
                prepend-icon="mdi-account-circle"
                v-model="loginId"
                :error-messages="errors"
                :success="valid"
                label="ユーザーID"
                outlined
              ></v-text-field>
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors, valid }"
              name="パスワード"
              rules="required"
            >
              <v-text-field
                prepend-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                v-model="password"
                :type="showPassword ? 'text' : 'password'"
                :error-messages="errors"
                :success="valid"
                label="パスワード"
                @click:append="showPassword = !showPassword"
                outlined
              ></v-text-field>
            </ValidationProvider>
            <v-btn
              block
              class="mt-4 info"
              type="submit"
              :disabled="invalid || isProcessing"
              >ログイン</v-btn
            >
          </v-form>
        </ValidationObserver>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
    <v-card width="400px" class="mx-auto mt-5">
      <v-card-title class="text-red"
        >【重要】メンテナンスのお知らせ</v-card-title
      >
      <v-card-text>
        <p>下記の時間帯において、ヤトイテのメンテナンスを実施致します。</p>
        <p class="font-weight-bold text-black text-center my-5">
          <span class="big">2025年02月20日(木) AM3:00 〜 AM6:00</span
          ><br /><br />
          メンテナンス中は全てのサービスが利用できません。<br />更新機能も停止するため、更新が必要な際は、直接広告媒体にログインしての更新作業をお願い致します。
        </p>
        <p>
          ご利用中の皆様には、大変ご迷惑をお掛けしますが、何卒ご理解の程よろしくお願い申し上げます。
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  defineComponent,
  onMounted,
} from "@vue/composition-api";
import { useRouter } from "@/utils";
import store from "@/store";
import { repositoryFactory } from "@/repository/repositoryFactory";
import setting from "@/common/setting.js";

export default defineComponent({
  name: "Login",
  props: ["loginid", "token"],
  setup(props, ctx) {
    // 初期化処理
    store.dispatch("auth/logoutUser"); // ログイン情報(vuex)を初期化
    store.dispatch("users/unsetUser"); // ユーザー情報(vuex)を初期化
    store.dispatch("shops/unsetShop"); // 店舗情報(vuex)を初期化
    store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
    const UsersRepository = repositoryFactory.get("users");
    const VerRepository = repositoryFactory.get("ver");
    const { router } = useRouter();
    const state = reactive({
      loginId: "",
      password: "",
      showPassword: false,
      isProcessing: false,
    });

    const login_label = process.env.VUE_APP_TITLE + " ログイン";

    // ログイン処理
    const onSubmit = async () => {
      // 状態を処理中にしてボタンを非活性化
      state.isProcessing = true;

      // 入力チェック
      const isValid = await ctx.refs.observer.validate();

      if (!isValid) {
        // エラー処理
        state.isProcessing = false;
      } else {
        // ログインパラメータ代入(Method:post、Content-Type:application/x-www-form-urlencoded;charset=UTF-8で送る場合)
        const params = new URLSearchParams();
        params.append("username", state.loginId); // ユーザー名(usernameにloginIdを入れる)
        params.append("password", state.password); // パスワード
        // ログイン認証
        const user_data = await UsersRepository.login(params)
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            throw (
              "ERROR:Login.vue/onSubmit UsersRepository.login (" + error + ")"
            );
          });

        if (user_data) {
          // ログインユーザーをvuexに入れる
          user_data["type"] = "user";
          store.dispatch("auth/loginUserAuth", user_data);
          // 店舗情報をvuexに入れる
          if (user_data.shop_list.length) {
            store.dispatch("shops/setShopList", user_data.shop_list);

            // ユーザー情報取得
            await UsersRepository.get(store.getters["auth/getUserId"]).then(
              (response) => {
                if (response.data) {
                  store.dispatch("users/setUser", response.data);
                }
              }
            );

            // フォーム初期化
            state.loginId = "";
            state.password = "";
            ctx.refs.observer.reset();

            // ホームに移動
            router.push({ name: "home" });
          } else {
            // 店舗登録フォームへ
            console.log("店舗登録がありませんでした");
          }
        } else {
          alert("ユーザー名またはパスワードが違います。");
        }
        state.isProcessing = false;
      }
    };

    const init = async () => {
      state.isProcessing = true;

      console.log(setting.system_version);

      // バージョンチェックする
      const vCheck = await VerRepository.check()
        .then((response) => {
          if (response.data) {
            console.log(response.data.version);
            if (response.data.version != setting.system_version) {
              console.log("最新バージョンに更新します");
              // eslint-disable-next-line
              location = location;
            }
            return true;
          }
        })
        .catch((error) => {
          alert(
            "ヤトイテのサーバと通信できませんでした。\nサーバがメンテンナンス中か、又は、インターネットに接続されていません。"
          );
          return false;
        });

      if (vCheck) {
        state.isProcessing = false;
      }

      // propsがある場合、直接ログインする
      if (props.token) {
        console.log("props", props);
        // ログイン認証
        const params = {
          "loginid": props.loginid,
        };
        const user_data = await UsersRepository.impersonate(params)
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            throw (
              "ERROR:Login.vue/onSubmit UsersRepository.impersonate (" +
              error +
              ")"
            );
          });

        if (user_data) {
          // ログインユーザーをvuexに入れる
          user_data["type"] = "user";
          user_data["access_token"] = props.token;
          store.dispatch("auth/loginUserAuth", user_data);
          // 店舗情報をvuexに入れる
          if (user_data.shop_list.length) {
            store.dispatch("shops/setShopList", user_data.shop_list);

            // ユーザー情報取得
            await UsersRepository.get(store.getters["auth/getUserId"]).then(
              (response) => {
                if (response.data) {
                  store.dispatch("users/setUser", response.data);
                }
              }
            );
            // ホームに移動
            router.push({ name: "home" });
          } else {
            alert("契約中の店舗が無いため、ログインできませんでした。");
          }
        } else {
          alert("なりすましログインに失敗しました");
        }
      }
    };

    onMounted(async () => {
      await init();
    });

    return {
      login_label,
      ...toRefs(state),
      onSubmit,
    };
  },
});

/* バリデーションを即時に判定しない場合
ValidationObserverタグの「 v-slot="{ invalid }"」を削除し、
ValidationProviderタグの全部に、mode="passive"を入れて
v-btnタグの「:disabled="invalid || isProcessing"」を「:disabled="isProcessing"」に
変更すると、ログインボタンを押したときにバリデーションが走る。

参考 https://vee-validate.logaretm.com/v3/guide/interaction-and-ux.html#interaction-modes
*/
</script>
